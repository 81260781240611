@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --bodyClr: #2196f3;
  --primaryClr: #296ca3;
  --secondaryClr: #132b41;
  --whiteClr: #fff;
  --headClr: #07131c;
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
  --primaryClr2: #5243dd;
  --themeClr1: #709DFF;
  --themeClr2: #852EFF;
  --bannerClr:#384a7f;
  --themeBackgroundClr: linear-gradient(0deg, var(--themeClr1), var(--themeClr2) 100%);
  --btn1BackgroundClr: linear-gradient(to left, #5243dd, #5156e6, #5368ed, #5978f4, #6188f9, #6289fb, #6389fd, #648aff, #627aff, #6867ff, #7450ff, #852eff);
}

body {
  font-family: "Roboto", sans-serif;
  background: var(--themeBackgroundClr) ;
  background-repeat: no-repeat;
  min-height: 100vh;
  color: #fff;
  font-size: 14px;
}

a {
  text-decoration: none;
}
.Toastify__toast-container,
.Toastify__toast {
  z-index: 111111 !important;
}
.btn-primary {
  background: #c9a145;
  border-color: #c9a145;
  border-radius: 50px;
  font-size: 13px;
  padding: 6px 25px;
}
.bg-light {
  background-color: #ededed !important;
}
.main {
  padding-bottom: 40px;
  margin-top: 126px;
  // padding-top: 150px;
  // min-height: 75vh;
  @media only screen and (max-device-width: 768px) {
    margin-top: 150px;
  }
  @media only screen and (max-device-width: 425px) {
    margin-top: 126px;
  }
  @media only screen and (max-device-width: 375px) {
    margin-top: 125px;
  }
  @media only screen and (max-device-width: 320px) {
    margin-top: 155px;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.main{
  .HomePage {
    .title-4{ font-size: 1.5rem;}
    .banner-slick{
      .slick-list{
        border-radius: 50px;
        .banner-slick-item{
          .home-page-banner{
            height: 400px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--bannerClr);
            background-size: cover;
            background-position: center;
            font-size: 96px;
            border-radius: 50px;
            img{
              height: inherit;
              border-radius: 50px;
            }
            @media only screen and (max-device-width: 768px) {
              height: 350px;
              img{
                height: inherit;
                border-radius: 25px;
              }
            }
          }
        }
      }
    }
    .home-sections{
      padding: 0px 100px;
      @media only screen and (max-device-width: 1100px) {
        padding: 0px 75px;
      }
      @media only screen and (max-device-width: 770px) {
        padding: 0px 50px;
      }
      @media only screen and (max-device-width: 425px) {
        padding: 0px 10px;
      }
    }
    .home-section-1{
      margin-bottom: 50px;
      .security-text{ color: var(--headClr); }
      .text-1{ color: #00bbff}
      .text-2{ color: var(--whiteClr); }
    }
    .home-popular-games{
      .show-all{ color: #00bbff}
      .popular-games-slick-item{ padding: 10px !important;}
    }
    .home-popular-casino-games{
      .show-all{ color: #00bbff}
      .popular-games-slick-item{ padding: 10px !important;}
    }
    .home-section-2{
      margin-bottom: 50px;
      .video-box{
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 96px;
        border-radius: 50px;
        background: #2b5876;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to left, #4e4376, #2b5876);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to left, #4e4376, #2b5876); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
    }
    .home-login-box{
      background: linear-gradient(to left, #FFCC00, #BD0473);
      padding: 10px 25px;  
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .login-btn{ 
        background-color: #BD0473;
        font-size: x-large;
        display: flex;
        flex-wrap: nowrap;
        color: var(--whiteClr);
        justify-content: center;
        align-items: center;
        padding: 5px 25px;
        border-radius: 25px;
      }
    }
  }
}
.home-section-4{
  .home-select {
    position: relative;
    height: 40px;
    gap: 10px;
    padding: 0px 10px;
    background-color: var(--themeBackgroundClr);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    color: #FFFFFF;
    select{
      background: none;
      border: 0;
      font-size: large;
      &:focus-visible{ outline: none; }
      ::-ms-expand {
        display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
      }
      &:first-child{
        color: #FFFFFF;
      }
      option{
        background-color: none;
        color: #000000;
      }
      
    }
  }
  
  .option {
    padding:0 30px 0 10px;
    min-height:40px;
    display:flex;
    align-items:center;
    background:#333;
    border-top:#222 solid 1px;
    position:absolute;
    top:0;
    width: 100%;
    pointer-events:none;
    order:2;
    z-index:1;
    transition:background .4s ease-in-out;
    box-sizing:border-box;
    overflow:hidden;
    white-space:nowrap;
    
  }
  
  .option:hover {
    background:#666;
  }
}
.privay-content,
.responsible-game {
  h3,
  h2 {
    color: #e9c149;
    font-size: 22px;
  }
}
input.form-control,
select.form-control {
  background: transparent !important;
  border-color: #c9a145;
  border-radius: 0;
  color: #e3e3e3 !important;
  font-size: 14px;
  font-weight: 300;
  box-shadow: none !important;
}
.btn {
  border-radius: 50px;
  font-size: 14px;
}
.btn-primary,
.btn-primary:hover {
  background: linear-gradient(
    90deg,
    rgba(41, 108, 163, 1) 0%,
    rgba(13, 52, 84, 1) 100%
  ) !important;
  border-color: #296ca3 !important;
}

.button-link {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 0;
  margin: 0 5px;
}

.header {
  // padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--themeBackgroundClr);
  width: 100%;

  .logo {
    max-width: 128px;
    img {
      max-height: 50px;
    }
  }

  .btn-login{
    background: var(--bs-btn-bg) !important;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color) !important;
    box-shadow: 0px 0px 3px 3px #fbfbfb63;
  }

  .mainNav {
    align-items: center;
    display: none;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    ul {
      li {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0 !important;
        }
        a {
          color: #fff;
          fill: #fff;
          stroke: #fff;
          display: flex;
          align-items: center;
          &.active {
            color: #fbc740;
          }
          svg,
          img {
            max-height: 20px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    fill: #ffffff;
    ul {
      list-style: none;
      padding: 0;
      li {
        position: relative;
        margin-right: 15px;
        p {
          position: absolute;
          left: -7px;
          font-size: 12px;
          margin-bottom: 0;
          color: #fff;
        }
      }
    }

    .path-stroke {
      stroke: #ffffff;
    }
    .Deposit_btn {
      button {
        border-radius: 4px;
      }
    }
    .wallet-card {
      border-radius: 4px;
      margin-bottom: 0;
      background: linear-gradient(
        90deg,
        rgb(41, 108, 163) 0%,
        rgb(13, 52, 84) 100%
      ) !important;
      // border: 1px solid #296ca3 !important;
    }

    .menu-btn {
      margin-left: 8px;
      padding: 0;
      background: none;
      border: none;

      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }

      svg {
        width: 25px;
        height: auto;
        fill: #fff;
      }
    }
  }

  .header-2 {
    background-color: var(--primaryClr2);
    .navbar-toggler:focus {
      box-shadow: 0 0 0 0.1rem #212529;
    }
    .nav-link {
      color: #ffffff;
      font-size: medium;
      text-align: center;
      img,
      svg {
        width: 20px;
        margin: 0px 5px 0px 5px;
        color: #ffffff;
      }
      span {
        color: #ffffff;
        text-wrap: nowrap;
      }
      @media only screen and (max-device-width: 767px) {
        // display: none;
        text-align: left;
      }
    }
  }
}
.header .headerRight ul {
  align-items: center;
  // display: flex;
  // justify-content: center;
}
.header .headerRight ul.loggedout {
  // transform: translateY(-5px);
  // margin-right: 10px !important;
  margin: 0px;
}
.header .headerRight ul li {
  position: relative;
}
.header .headerRight ul li {
  margin: 0 5px;
}
.wallet-card li {
  // border-right: 1.5px solid hsla(0, 0%, 100%, 0.642);
  color: hsla(0, 0%, 100%, 0.819);
  border: 1px solid #296ca3 !important;
  display: flex;
  flex: 1 1;
  justify-content: center;
  margin-left: 5px;
  padding: 5px;
  margin-bottom: 0;
}
.main {
  @media screen and (min-width: 768px) {
    // display: flex;
  }
  &.HomePage {
    // padding-top: 100px !important;
  }

  .TopCategories {
    margin: 0 0px 30px 0;
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      // background: #215783;
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 10px;
      flex-wrap: nowrap;

      li {
        font-size: 11px;
        padding: 0px 0px;
        text-align: center;
        // background: #215783;
        min-width: 80px;
        .nav-link {
          border: 0;
          border-radius: 0 !important;
          color: #fff;
          font-size: 14px;
          height: 100%;
          padding: 10px 5px 5px;
          width: 92px;
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          img {
            width: 30px;
          }
          &.active {
            background: var(--primaryClr2) !important;
          }
        }
      }
    }
  }

  .carousel-indicators [data-bs-target] {
    width: 18px;
    border-radius: 50%;
    height: 18px;
    background-color: #132b41;
    margin: 0 10px;
    opacity: 1;
  }
  .carousel-indicators .active {
    background-color: #fbd35f;
  }

  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    padding-bottom: 5px;

    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: #296ca3;
    }
  }

  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 10px #0f121a;
    .link {
      width: 100%;
      background: #296ca3;
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      padding: 8px;
    }
  }

  .payment-info {
    color: white;
    @media screen and (min-width: 768px) {
      margin: 11px 0 0 0;
      background-color: var(--headClr);
      padding: 8px;
      padding: 12px;
    }
    ul {
      display: flex;
      align-items: center;
      li {
        margin-right: 1%;
      }
    }

    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      img {
        width: 28px;
        margin-right: 14px;
      }
      svg {
        margin-right: 14px;
      }
    }

    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }

    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;

      &.btn-outline-secondary {
        border-color: #fff;
        color: #fff;
      }
    }
  }
  .payment-info.social {
    background-color: transparent !important;
    position: fixed;
    right: 0;
    top: 30%;
    width: 70px;
    margin: -30px -1rem;
    z-index: 99;

    ul {
      flex-direction: column;
      position: relative;
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 10px;
        width: 4%;
        a {
          align-items: center;
          background-color: rgb(0 0 0 / 56%);
          border: 2px solid #4a4a4a;
          border-radius: 5px;
          display: flex;
          height: 60px;
          position: relative;
          right: 45px;
          transition: all 0.3s;
          width: 70px;
          cursor: pointer;
          &:hover {
            right: 250px;
            width: 300px;
          }
          img {
            height: auto;
            margin: 0 15px;
            width: 35px;
          }
          p {
            color: #fff;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            margin: 0;
          }
        }
      }
    }
  }
  .account-statement {
    input[type="date"] {
      z-index: 999999 !important;
      padding-right: 7px;
      padding-left: 0.35rem;
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
    .search-icon {
      button {
        height: calc(100% - 2px) !important;
        top: 1px;
        right: 1px;
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 34px;
      display: flex;
      height: 100% !important;
      justify-content: center;
      align-items: center;
      border: 0 !important;
      top: 0;
      padding: 0 !important;
      z-index: 9999 !important;
    }
    @media screen and (min-width: 768px) {
      padding: 0 1rem;
    }
    button.calender-icon {
      svg {
        display: none;
      }
    }

    h3 {
      border-bottom: 1px solid #fff;
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;

      @media screen and (min-width: 768px) {
        margin: 12px 0rem 22px 0rem;
        padding-left: 0;
      }
    }

    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }

    ul {
      margin: 0 -1rem;
      border-top: 1px solid #fff;

      &:empty {
        border: none;
      }

      li {
        padding: 22px 1rem;
        border-bottom: 1px solid #fff;

        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }

        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }

        .details {
          font-size: 12px;
          font-weight: 300;
        }

        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }

        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
  }
  .calender-icon {
    align-items: center;
    border: 0 !important;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    position: absolute;
    right: 0;
    top: 1px;
    width: 33px;
    height: 32px;
    margin-right: 1px;
    z-index: 6 !important;
    border-radius: 0;
    cursor: pointer;
  }
  .pointer-none {
    pointer-events: none;
  }
  .statement-table {
    th,
    td {
      background: none;
      color: #fff;
    }
  }

  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
      max-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid #c9a046;
    }

    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .edit-btn {
    background: #0b1e2c;
    border: 1px solid #fff;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: #fff;
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #fff;
    }
  }

  .profile-info {
    th {
      background: #07131c;
      color: var(--whiteClr);
    }

    td {
      background: #22313c;
      color: #fff;
      border-color: #010000;
      button.btn-primary {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        padding: 5px;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
        }
      }
    }
    td.reset-pwd {
      display: flex;
      justify-content: flex-end;
      button {
        display: flex;
        color: #000;
        border: 0;
        background: transparent;
        font-size: 13px;
        align-items: center;
      }
    }

    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}
.listings {
  .filter {
    margin-bottom: 20px;
    max-width: 500px;

    .dropdown-toggle {
      background: #c9a145 !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }

    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: #fff;
      border-radius: 0 !important;
    }

    .dropdown-menu {
      border-radius: 0;
      border: 1px solid #e8e8e8;
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;

      a {
        border-bottom: 1px solid #e8e8e8;
        color: #26324b;
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;

        &:last-child {
          border: none;
        }
      }
    }
  }

  img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
    aspect-ratio: 12 / 9;
    object-fit: cover;
  }

  .like-btn {
    display: none;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;

    svg {
      fill: #fff;
    }
  }
}

.PagesHeading {
  background-color: var(--primaryClr2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 50px;
  margin-bottom: 20px;
  h2 {
    font-size: 18px;
    margin: 0;
    color: var(--whiteClr);
  }
}
.back-link {
  font-size: 10px;
  color: var(--whiteClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  padding: 6px 2px 6px 10px;
  border-radius: 5px;
  font-weight: 700;
  &:hover {
    background-color: var(--primaryClr);
  }
  svg {
    margin-left: 5px;
  }
}
.deposit-form,
.withdraw-form {
  input.form-control::placeholder {
    color: #ffffff8f !important;
  }
}
.sign {
  background: #050f16 !important;
  opacity: 1 !important;
}
.offcanvas {
  border: none !important;
  z-index: 11111 !important;
}

.offcanvas-header {
  background: var(--themeBackgroundClr);
  color: #fff;

  .profile-img {
    width: 48px;
    height: 48px;
    background: #26324b;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: white;
  }

  .user-info {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }

  .btn-close {
    opacity: 1;
  }
}

.offcanvas-body {
  background: var(--themeBackgroundClr);

  .balance {
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      margin-right: 14px;
    }
    svg {
      margin-right: 14px;
    }
  }

  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
  }

  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    background: linear-gradient(
      90deg,
      rgb(41, 108, 163) 0%,
      rgb(13, 52, 84) 100%
    ) !important;
    color: var(--whiteClr);
    border-color: #296ca3 !important;
  }

  .menu-items {
    border-top: 1px solid #323232;
    margin: 30px -1rem 0 -1rem;
    list-style: none;
    padding-left: 0;

    li {
      border-bottom: 1px solid #323232;

      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: #fff;

        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
        }
        img {
          width: 22px;
          margin-right: 12px;
        }
      }
    }
  }

  .transfer-form {
    border-top: 1px solid #fff;
    margin: 10px -1rem 0 -1rem;
    padding: 10px;
    overflow: hidden;

    input {
      outline: none;
      padding: 5px 15px;
      font-size: 1rem;
      color: white;
      background: #000;
    }
  }
}

.form-steps {
  text-align: center;
}

.steps-canvas {
  .offcanvas-header {
    background: var(--themeBackgroundClr);

    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
  }

  .form-steps {
    padding: 55px 30px 0;
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: #fff;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
    }
    input {
      background: #26324b !important;
      border-radius: 10px;
      border-color: #26324b;
      padding: 14px 20px;
      color: #fff;
    }
    input::placeholder {
      color: #cccccc8e;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: #646464;
    }

    .btn {
      padding: 9px 30px;
    }

    .form-link {
      font-size: 14px;
      color: var(--whiteClr);
      text-decoration: underline;
    }

    .sign-up {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      a {
        font-size: 16px;
        color: #4a6191;
        text-decoration: underline;
      }
    }
  }
}
.LoginBtn {
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}
.bank-types {
  .nav-link {
    padding: 12px 15px;
    border: 2px solid transparent;
    background: #041d34;
  }
  .nav-link.active {
    border: 2px solid #c9a145;
    background: #041d34;
  }
  img {
    width: 60px;
  }
}
// inplay

.inPlay {
  .nav-pills {
    background: #fff;
    width: 100%;
    height: 100%;
    overflow: scroll hidden;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 2.25rem;
    scrollbar-width: none;
    border-radius: 12px;
    padding: 10px;
    .nav-item {
      margin: 5px 7px;
    }
    .nav-link {
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 12px 15px;
      color: #5c6060;
      box-shadow: 0px 2.61538px 7.84615px rgba(220, 220, 220, 0.6);
      &.active {
        background: #0134a9 !important;
        color: #fff;
      }
    }
  }
}
.footer {
  // background-color: #07131c;
  // background-color: var(--headClr);
  background: var(--themeBackgroundClr);
  padding: 20px 0 10px;
  .footer-box{
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background: #5C6587; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    .footer-card{
      min-width: 175px;
      .footer-links{
        display: flex;
        flex-direction: column;
        a{
          color:var(--whiteClr);
        }
      }
    }
  }
  
  @media only screen and (max-device-width: 768px) {
    padding: 20px 0 110px;
  }

  @media only screen and (max-device-width: 580px) {
    padding: 20px 20px 110px;
  }
}

.footer-links {
  display: inline;
  margin: 0px auto 10px auto;

  ul {
    text-align: center;
    padding: 0;

    li {
      font-size: 12px;
      font-weight: 300;
      display: inline-block;
      position: relative;
      margin: 5px 15px;

      &:after {
        content: " ";
        position: absolute;
        right: -16px;
        top: 2px;
        width: 1px;
        height: 14px;
        background: #202020;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      a {
        color: var(--whiteClr);
      }
    }
  }
}

.social-icons {
  ul {
    display: flex;
    justify-content: center;
    list-style: none;

    li {
      margin: 10px;

      svg {
        width: 32px;
      }
    }
  }
}

.footer-menu {
  position: fixed;
  bottom: 0px;
  background-color: #07111a;
  border-top: 1px solid #0c1e2c;
  z-index: 9999;

  @media screen and (min-width: 768px) {
    display: none;
  }

  ul {
    li {
      text-transform: uppercase;
      font-size: 10px;
      min-width: 54px;

      a {
        padding: 15px 5px 10px 5px;
        color: var(--whiteClr);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;

        svg,
        img {
          fill: currentColor;

          min-height: 25px;
          width: 25px;
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
        }

        span {
          margin-top: 5px;
        }

        &.active {
          color: #3aa3f5;

          svg {
            fill: currentColor;
            stroke: currentColor;
          }
        }
      }

      &.home {
        background: #000;
        border-radius: 60px;
        bottom: 20px;
        box-shadow: 0 4px 20px #39a4f4;
        height: 65px;
        padding: 6px;
        position: relative;
        width: 65px;
        font-size: 0;
      }
    }
  }
}
.footer-logo {
  max-width: 130px;
  margin: 0 auto;
}

.sidenav-menu {
  padding-left: 58px;
  transition: 0.3s;
}
.sidenav-menu.active {
  padding-left: 195px;
  transition: 0.3s;
}

.sidenav-menu.active {
  .sidenav {
    width: 185px;
    transition: 0.3s;
  }
}
.collapsible {
  padding: 0;
  list-style: none;
  margin: 0;
}
.sidenav {
  margin-top: 122px;
  left: 10px;
  border-radius: 12px;
  background-color: transparent;
  padding-bottom: 0;
  height: auto;
  position: fixed;
  top: 30px;
  bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 50px;
  box-shadow: none;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}
.sidenav::-webkit-scrollbar {
  display: none;
}
.sidenav .sidenav-height {
  height: auto;
  overflow: inherit;
  margin-bottom: 12px;
  background: #fff;
  border-radius: 12px;
  padding-bottom: 0;
  display: block;
  padding-left: 0;
  width: 100%;
}
.sidenav .collapsible li {
  position: relative;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  height: auto;
  line-height: 1.6;
  color: #737070;
  transition: all 0.1s;
  display: flex;
  width: 100%;
  padding: 7px;
}
.sidenav .collapsible li a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #2c2a2a;
  width: 100%;
  transition: 0.3s;
  border-radius: 9px;
}
.sidenav .collapsible li a span {
  white-space: nowrap;
}
.sidenav .collapsible li a:hover {
  background: #deebff;
  .icon-holder-small {
    background: #deebff;
  }
}
.sidenav .icon-holder-small {
  background: #f8f8f8;
  border-radius: 8px;
  min-width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  z-index: 9;
  position: relative;
}
.sidenav .collapsible li.active .icon-holder-small {
  background: #deebff;
}
.market-list {
  background-color: #fff;
  border-radius: 10px;
  margin: 0px 0 10px 0;
  padding: 10px 0;
  padding-top: 45px;
  .market-data {
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    margin: 0 10px 5px 10px;
    padding: 15px 15px 15px 10px;
    border-radius: 8px;
    position: relative;
    background: #f6f9ff;
  }
  .in-play {
    position: relative;
    background: linear-gradient(
      to right,
      #1f4aad -30%,
      #1f4aad 20%,
      #1f4aad 130%
    );
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: initial;
    height: 39px;
    width: 65px;
    font-weight: 500;
    border-radius: 5px;
  }
  .game-name {
    color: #212529;
  }
}
.in-play:after,
.in-play:before {
  content: "";
  z-index: 0;
  color: #144272;
  border-radius: 7px;
  margin: -5%;
  -webkit-animation: 8s linear infinite clipMe;
  animation: 8s linear infinite clipMe;
  position: absolute;
  inset: 0;
  box-shadow: inset 0 0 0 2px;
}

.in-play::before {
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
}
@-webkit-keyframes clipMe {
  0%,
  to {
    clip: rect(0, 75px, 2px, 0);
  }

  25% {
    clip: rect(0, 2px, 75px, 0);
  }

  50% {
    clip: rect(45px, 75px, 75px, 0);
  }

  75% {
    clip: rect(0, 75px, 75px, 45px);
  }
}

@keyframes clipMe {
  0%,
  to {
    clip: rect(0, 75px, 2px, 0);
  }

  25% {
    clip: rect(0, 2px, 75px, 0);
  }

  50% {
    clip: rect(45px, 75px, 75px, 0);
  }

  75% {
    clip: rect(0, 75px, 75px, 45px);
  }
}

@-webkit-keyframes up-animation {
  0% {
    top: -100px;
  }

  50% {
    top: -50px;
  }

  75%,
  99.99% {
    top: 0;
  }

  to {
    top: -100px;
  }
}

@keyframes up-animation {
  0% {
    top: -100px;
  }

  50% {
    top: -50px;
  }

  75%,
  99.99% {
    top: 0;
  }

  to {
    top: -100px;
  }
}
.matchrate {
  .back-rate,
  .lay-rate {
    height: 45px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    small {
      font-size: 10px;
    }
  }
  .back-rate {
    background-color: #bbe1ee;
    border-radius: 8px;
    color: #106481;
  }
  .lay-rate {
    background-color: #f6c5c8;
    border-radius: 8px;
    color: #af4d52;
  }
}
.collapse-bg {
  background-image: url(https://cdn.cloudd.live/theme/powerplay247_theme/powerplay247/assets/images/collapse-title-bg.png?v=1.3);
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  width: auto;
  min-width: 120px;
  height: 35px;
  float: left;
  text-align: center;
  top: -4px;
  margin-left: 10px;
}
.collapse-title {
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 12px 5px 10px;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
}
.collapse-title .counter {
  font-size: 12px;
  margin-left: 3px;
  line-height: 12px;
}

/* bet slip */
.betslip {
  background: #fff;
  right: 0;
  top: 56px;
  border-radius: 12px;
}
.betslip-header {
  border-bottom: 1px solid #f5f4f1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}

.betslip-header button {
  display: none;
}
.betslip-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  padding: 0 10px;
  min-height: 250px;
  position: relative;
}

.betslip-body .blank-data svg {
  color: #bbb5aa;
  margin-bottom: 15px;
}
.betslip-body .blank-data p {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0;
}
.betslip-body .blank-data span {
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  text-transform: none;
  color: #7b756b;
}
.card-match-details {
  width: 100%;
  background: #f5f4f1;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
}
.card-match-details small {
  opacity: 0.9;
}
.betslip-body .close-btn {
  position: absolute;
  right: 13px;
  top: 13px;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0.6;
}

table td,
table th {
  font-size: 12px;
  padding: 4px 8px !important;
  vertical-align: middle;
}
.withdraw label {
  font-size: 12px;
  font-weight: 500;
}
.withdraw ul {
  list-style: none;
  background: var(--primaryClr2);
  padding: 30px;
  border-radius: 10px;
}
.withdraw li {
  font-size: 13px;
  margin-bottom: 15px;
  display: flex;
}

.withdraw li span {
  font-size: 20px;
  margin-left: -10px;
  margin-right: 10px;
  margin-top: 2px;
  color: #ffffff;
}
.account-details {
  background: #132b41;
  padding: 20px 0;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #eeeeee86;
}
.sectionList {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 15px 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eeeeee86;
}
.sectionList:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}
.sectionList p {
  font-size: 13px;
  margin-bottom: 0;
}
.sectionList > div {
  display: flex;
}
.sectionList > div span {
  font-size: 16px;
  margin-left: 5px;
  color: #1976d2;
}
.qa_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}
.qa_wrap .btn {
  width: 31.5%;
  padding: 6px;
}
.odd-title-bg {
  width: fit-content;
  position: relative;
  float: left;
  margin: auto;
  margin-right: 20px;
}
.odd-title-bg .match-odd {
  margin-bottom: 10px;
  margin-top: 10px;
}
.match-odd .scores-event {
  font-size: 13px;
  font-weight: 600;
  line-height: 2.2;
  color: #0134a9;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background-color: #fefeff;
  width: fit-content;
  padding: 0 0 0 15px !important;
  position: relative;
  box-shadow: 15px 6px 21px rgba(85, 98, 142, 0.23);
}
.match-odd .scores-event::after {
  content: "";
  position: absolute;
  top: 0;
  right: -19.3px;
  border-left: 10px solid #fefeff;
  border-top: 15px solid #fefeff;
  border-right: 10px solid transparent;
  border-bottom: 14px solid transparent;
}

.bet-input-container {
  background: #f9f9f9;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  .bs-top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 3px 10px;
    &.back {
      background: #caeffb;
    }
    &.lay {
      background: #ffd7d9;
    }
    h6 {
      font-weight: 600;
      color: #116481;
    }
    .form-control {
      width: 90px;
      height: 28px;
      padding: 5px;
      text-align: center;
      font-size: 13px;
      color: #116481 !important;
      border-color: #11638162;
    }
  }
  .bid-rate {
    margin-top: 10px;
    justify-content: end;
    .btn {
      font-size: 12px;
      font-weight: 600;
      border-color: #11638154;
      color: #116481;
      border-radius: 2px;
      &:hover {
        background: #bbe1ee;
      }
    }
  }
  p {
    color: #b2bcd7;
  }
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.change-password {
  .form-group {
    position: relative;
    .form-control {
      background: none !important;
      color: #fff !important;
    }
    .eye {
      position: absolute;
      right: 10px;
      top: 30px;
      padding: 0;
      border: 0;
      zoom: 90%;
    }
  }
}

.matka-main {
  min-height: 90vh;
}
.matka-box {
  background-color: var(--bg-table);
  border: 1px solid #6d6d6d;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  transition: 0.3s;

  p {
    color: #e0e0e0 !important;
    font-size: 12px;
    line-height: 2;
    margin-bottom: 1px;
  }

  hr {
    border: 0;
    border-top: 1px solid #eee;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .for_mobile_result {
    color: #bce2ff;
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
  }

  .text-info1 {
    color: #fff;
  }

  img {
    height: 20px;
    margin-top: -8px;
    object-fit: contain;
    width: 60px;
  }

  .text-button {
    background-color: green;
    border-radius: 5px;
    color: #fff !important;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 15%;
    padding: 1px 8px;
    width: auto;
  }
}

.m-details {
  margin-left: -4px;
  margin-right: -4px;

  .col {
    min-width: 8.33%;
    max-width: 8.33%;
    padding: 0 4px;
  }
}

.matka-details {
  .nav-item {
    width: 50%;

    .nav-link {
      width: 100%;
    }
  }
}
.select-bet {
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  line-height: 0;
}
.select-bet .selectbet-header {
  background: linear-gradient(
    343.81deg,
    rgba(232, 238, 255, 0.39) 10.83%,
    #ffffff 85.46%
  );
  position: relative;
  display: flex;
  border-radius: 12px;
  line-height: 1.5;
  margin: 10px 0 10px 0;
}
.select-bet .selectbet-header .bet-teamname {
  padding: 3px 0 0 0;
  font-weight: 600;
  font-size: 14px;
}
.countdownHolder {
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -27px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://cdn.cloudd.live/theme/powerplay247_theme/powerplay247/assets/images/timer-bg.png);
  width: 260px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
}
.select-bet .selectbet-header .bet-teamname .top-header-module {
  padding: 0 5px;
}
.select-bet .selectbet-header .bet-teamname .top-header-module .top-row {
  display: block;
  position: relative;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .text-counter {
  padding: 0 5px;
  text-align: center;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .evt-title {
  padding: 0 20px;
  height: 70px;
  color: #fff;
  line-height: 1.5;
  font-weight: 600;
  font-size: 14px;
  right: 90px;
  left: 10px;
  text-align: center;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .evt-title
  .live-title
  div {
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .evt-title
  .live-match {
  color: #00b181;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  animation-name: blinker2;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .evt-title
  .live-title
  div {
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .text-counter
  .teams-name {
  display: flex;
  align-items: center;
  position: relative;
  top: -15px;
  line-height: 1;
  text-align: center;
  justify-content: center;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .addmarket-icon {
  position: absolute;
  right: 5px;
  top: 6px;
  padding: 0 5px;
  z-index: 9;
}

.select-bet .selectbet-header .bet-teamname .live-stream {
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
  float: right !important;
  a {
    color: #0134a9;
    cursor: pointer;
    line-height: 1;
  }
  img {
    width: 100%;
    height: auto;
    margin-top: 2px;
  }
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .text-counter
  .teams-name {
  display: flex;
  align-items: center;
  position: relative;
  top: -15px;
  line-height: 1;
  text-align: center;
  justify-content: center;
}
.select-bet
  .selectbet-header
  .bet-teamname
  .top-header-module
  .top-row
  .text-counter
  .teams-name
  .date-details {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: -2px;
  display: flex;
  color: #0134a9;
}
.date-details .date-title {
  color: #a3bbff;
  text-transform: uppercase;
  min-width: 70px;
}
.top-header-module .bottom-row {
  display: block;
  align-items: center;
  padding: 0;
  position: relative;
  top: -10px;
  margin: 5px 0 0;
}
.top-header-module .bottom-row .event-name {
  padding-left: 0;
  margin: 10px 0 10px 0;
}
.top-header-module .bottom-row .event-name h4 {
  font-size: 22px;
  margin: 0;
  font-weight: 600;
  color: #666666;
  padding-left: 0;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.top-header-module .bottom-row .event-name h4 .width-50 {
  width: 50%;
}
.event-name h4 .vs-image-content {
  position: relative;
  width: 110px;
  height: 50px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
}
.bottom-row .event-name h4 .vs-image-content svg {
  width: 35px;
  height: 35px;
}
.bottom-row .event-name h4 .width-50 .left-team-title {
  display: flex;
  align-items: center;
  float: right;
  text-align: center;
  margin-right: 5px;
}
.bottom-row .event-name h4 .width-50 .left-team-title .team-bg {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  background-color: #fcfcfc;
  box-shadow: 0px 4px 10px rgba(115, 111, 111, 0.11);
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
}
.bottom-row .event-name h4 .width-50 .right-team-title .team-bg {
  margin-left: 10px;
  width: 60px;
  height: 60px;
  background-color: #fcfcfc;
  box-shadow: 0px 4px 10px rgba(115, 111, 111, 0.11);
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
}
.team-bg .title {
  color: #0134a9;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}
.event-name .team-title-full {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  line-height: 17px;
  color: #0134a9;
  width: 175px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottom-row .event-name h4 .width-50 .right-team-title {
  display: flex;
  align-items: center;
  float: left;
  text-align: center;
  margin-left: 5px;
}
@media (max-width: 768px) {
  // .main {
  //   padding-top: 130px;
  // }
  .header {
    .logo img {
      max-height: 45px;
    }
    .headerRight {
      .wallet-card {
        flex-direction: column;
        align-items: baseline;
        padding-left: 0;
        li {
          border: 0;
        }
      }
      .Deposit_btn {
        .btn {
          padding: 5px 10px;
        }
      }
    }
  }
  .main {
    .TopCategories {
      ul {
        // padding-right: 60px;
        li {
          min-width: auto;
          padding: 0 5px;
          .nav-link {
            width: auto;
            padding: 10px 7px 5px;
          }
        }
      }
    }

  }
  .qa_wrap .btn {
    width: 30.5%;
    padding: 6px;
  }
  .sidenav {
    left: -100%;
  }
  .sidenav-menu {
    padding-left: 0;
    &.active {
      padding-left: 0;
      .sidenav {
        z-index: 111;
        left: 0;
        background: #ededed;
        width: 250px;
        padding: 10px;
        margin-top: 66px;
      }
    }
    .close-menu {
      background: #fff !important;
      padding: 5px 10px;
      border-radius: 10px;
      border: 0;
      margin-bottom: 10px;
      width: 100%;
      text-align: left;
    }
  }
  .inPlay {
    .nav-pills {
      flex-wrap: nowrap;
    }
  }
  .market-list {
    .in-play {
      min-width: 70px;
    }
  }
  .bet-input-container {
    margin: 10px;
    padding-bottom: 10px;
  }
  .steps-canvas {
    .form-steps {
      padding: 55px 10px 0;
    }
  }
  // .iframe-data {
  //   height: 300px !important;
  // }
  .bottom-row .event-name h4 .width-50 .left-team-title .team-bg {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }
  .bottom-row .event-name h4 .width-50 .right-team-title .team-bg {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .event-name h4 .vs-image-content {
    width: auto;
    height: 45px;
    margin: 0 5px;
    border-radius: 25px;
  }
  .team-bg .title {
    font-size: 12px;
  }
  .event-name .team-title-full {
    width: 80px;
    font-size: 14px;
  }
}
